@import "configurations";

#change-password-form {
  .ra-input {
    margin-bottom: 10px;
  }

  .field-password {
    padding-top: 35px;
    margin: 0 auto;
  }

  .new-password-submit-btn {
    clear: both;
    width: 100%;
    height: 60px;
    text-align: center;
    border: 0 none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background-color: #4778d9;
    font-size: 13px;
    color: #ffffff;
    cursor: pointer;
  }
}

